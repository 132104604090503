import './MenuHandler';
import './VideoHandler';
import './share';
import './news';
import './questions';
import './maps';
import './feedbackform';
import Flickity from 'flickity';

document.addEventListener("DOMContentLoaded", function(event) {
    if (!Element.prototype.matches)
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
                                Element.prototype.webkitMatchesSelector;
if (!Element.prototype.closest)
    Element.prototype.closest = function(s) {
        var el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };

    let sliderHolder = document.querySelector('.slider-big-double');

    if ( sliderHolder !== null ) {
        var flkty = new Flickity( '.slider-big-double', {
            adaptiveHeight: true,
            selectedAttraction: 1,
            friction: 0.8,
            wrapAround: true,
            prevNextButtons: false,
            imagesLoaded: true
        });

        if ( flkty.slides.length == 1 ) {
            document.querySelector('.flickity-page-dots').remove();
        }
    }

    let toggleBlock = document.querySelectorAll('.js-toggle-contact');

    for (var i = 0; i < toggleBlock.length; i++) {
        toggleBlock[i].addEventListener("click", function(event) {
            event.preventDefault();

            this.closest('.contact-block').classList.toggle('visible');
        });
    }

    let toggleQuestion = document.querySelectorAll('.js-toggle-question');

    for (var i = 0; i < toggleQuestion.length; i++) {
        toggleQuestion[i].addEventListener("click", function(event) {
            event.preventDefault();

            this.closest('.question').classList.toggle('visible');
        });
    }

    let submenuToggle = document.querySelectorAll('.js-show-sub');

    for (var i = 0; i < submenuToggle.length; i++) {
        submenuToggle[i].addEventListener("click", function(event) {
            event.preventDefault();

            this.classList.toggle('active');
            this.closest('.has-sub').querySelector('ul').classList.toggle('active');
        });
    }

    let activeMenuItems = document.querySelectorAll('.current-page-parent');

    for (var i = 0; i < activeMenuItems.length; i++) {
        activeMenuItems[i].closest('.has-sub').querySelector('.show-sub').classList.add('active');
        activeMenuItems[i].closest('.has-sub').querySelector('ul').classList.add('active');
    }

    let activeMenuDeeper = document.querySelectorAll('.current-page-item');

    for (var i = 0; i < activeMenuDeeper.length; i++) {
        if ( activeMenuDeeper[i].closest('.has-sub') !== null ) {
            activeMenuDeeper[i].closest('.has-sub').querySelector('.show-sub').classList.add('active');
            activeMenuDeeper[i].closest('.has-sub').querySelector('ul').classList.add('active');
        }
    }

    // Filter programs
    let programFilterBtn = document.querySelectorAll('.js-filter-btn');
    let programHolders = document.querySelectorAll('.js-education-card');
    let showAllPrograms = document.querySelector('.js-filter-btn-all');

    for (var i = 0; i < programFilterBtn.length; i++) {
        programFilterBtn[i].addEventListener("click", function(event) {
            event.preventDefault();

            if ( this.classList.contains('active', 'btn-active') ) {
                this.classList.remove('active', 'btn-active');
            } else {
                this.classList.add('active', 'btn-active');
            }

            for (var e = 0; e < programHolders.length; e++) {
                programHolders[e].style.display = 'none';
            }

            let activeBtns = document.querySelectorAll('.btn-active');

            if ( activeBtns.length <= 0 ) {
                console.log('show all');
                for (var u = 0; u < programHolders.length; u++) {
                    programHolders[u].style.display = 'block';
                }

                document.querySelector('.js-filter-btn-all').classList.add('active');

                return false;
            } else {
                document.querySelector('.js-filter-btn-all').classList.remove('active');
            }

            for (var u = 0; u < activeBtns.length; u++) {
                let thisID = activeBtns[u].dataset.value;
                let findElements = 'school-' + thisID;

                let elementsToShow = document.querySelectorAll(findElements);

                for (var a = 0; a < programHolders.length; a++) {
                    if ( programHolders[a].classList.contains(findElements) ) {
                        programHolders[a].style.display = 'block';
                    }
                }
            }
        });
    }

    if ( showAllPrograms !== null ) {
        showAllPrograms.addEventListener("click", function(event) {
            event.preventDefault();

            let activeBtns = document.querySelectorAll('.btn-active');

            if ( this.classList.contains('active') ) {
                return;
            } else {
                this.classList.add('active');

                for (var e = 0; e < programHolders.length; e++) {
                    programHolders[e].style.display = 'block';
                }

                for (var u = 0; u < activeBtns.length; u++) {
                    activeBtns[u].classList.remove('active', 'btn-active');
                }
            }
        });
    }

});