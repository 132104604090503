// global variable for the player
var player = [];
var players = document.querySelectorAll('.video-player');
var playBtns = document.querySelectorAll('.play-button-full');

// this function gets called when API is ready to use
window.onYouTubePlayerAPIReady = function() 
{
    // create the global player from the specific iframe (#video)
    for( var i = 0; i < players.length; i++ ) {
        playBtns[i].dataset.value = i;

        player[i] = new YT.Player(players[i], {
            events: {
                // call this function when player is ready to use
                'onReady': onPlayerReady,
                'onStateChange': onPlayerStateChange
            }
        });
        player[i].el_number = i;
    }
}

function onPlayerReady(event) 
{
    playBtns[event.target.el_number].addEventListener("click", function() {
        if ( this.classList.contains('hidden') ) {
            player[event.target.el_number].pauseVideo();
            // this.classList.remove('hidden');
        } else {
            console.log(event.target.el_number);
            player[event.target.el_number].playVideo();
            this.classList.add('hidden');
        }
    });  
}

function onPlayerStateChange(event)
{

    if ( event.data == 0 || event.data == 2 ) {
        playBtns[event.target.el_number].classList.remove('hidden');
    }
    
    if ( event.data == 1 ) {
        playBtns[event.target.el_number].classList.add('hidden');
    }
}

// Inject YouTube API script
var tag = document.createElement('script');
tag.src = "//www.youtube.com/player_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);