window.$ = require('jquery');
window.jQuery = window.$;

var getUrlParameter = function getUrlParameter(sParam) {
      var sPageURL = decodeURIComponent(window.location.search.substring(1)),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

      for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
            }
      }
};

var qId = getUrlParameter('q');
if(qId) {
      $('.question[data-id='+qId+']').addClass('visible');
      
      $('html, body').animate({
            scrollTop: $('.question[data-id='+qId+']').offset().top
      }, 1000);
}