window.$ = require('jquery');
window.jQuery = window.$;

$(function() {
      $('.js-share-facebook').on('click', function(e){
            e.preventDefault();
		var picture = $(this).data('image');
		var desc 	= $(this).data('desc');
		var name 	= $(this).data('name');
		var link 	= $(this).attr('href');
		var caption = $(this).data('caption');

		FB.ui({
			display: "popup",
			method: 'feed',
			name: name,
			link: link,
			description: desc,
			caption: caption,
			picture: picture
		});
      });
});
