if(document.getElementById('feedbackForm')){
	let school = document.querySelector('#js-feedback-select-school');
	let selectedHead = document.querySelector('#js-feedback-selected-head');
	let inputAnon = document.querySelector('#js-feedback-anonymous');
	let consent = document.querySelector('#js-feedback-consent');
	let sendbutton = document.querySelector('#js-feedback-send');
	let form = document.querySelector('#feedbackForm');


	school.addEventListener('change', function(e){
		selectedHead.innerHTML = e.target.options[e.target.selectedIndex].dataset.info;
	});

	inputAnon.addEventListener('change', function(e){
		if(this.checked){
			document.querySelector('#fname').value = '';
			document.querySelector('#postadress').value = '';
			document.querySelector('#postnumber').value = '';
			document.querySelector('#city').value = '';
			document.querySelector('#phone').value = '';
			document.querySelector('#email').value = '';

			document.querySelector('#email').required = false;
			document.querySelector('#fname').disabled = true;
			document.querySelector('#postadress').disabled = true;
			document.querySelector('#postnumber').disabled = true;
			document.querySelector('#city').disabled = true;
			document.querySelector('#phone').disabled = true;
			document.querySelector('#email').disabled = true;
		}
		else
		{
			document.querySelector('#email').required = true;
			document.querySelector('#fname').disabled = false;
			document.querySelector('#postadress').disabled = false;
			document.querySelector('#postnumber').disabled = false;
			document.querySelector('#city').disabled = false;
			document.querySelector('#phone').disabled = false;
			document.querySelector('#email').disabled = false;
		}
	});


	consent.addEventListener('change', function(e){
		if(this.checked)
		{
			sendbutton.disabled = false;
		}
		else
		{
			sendbutton.disabled = true;
		}
	});

	form.addEventListener('submit', function(e){
		var response = grecaptcha.getResponse();
		if(response.length == 0)
		{
			document.querySelector('#error').innerHTML = 'Du måste godkänna att du inte är en robot.';
			e.preventDefault();
		}
	});
}