let mainMenu = document.querySelector('#js-menu');
let menuBtn = document.querySelector('#js-toggle-menu');
let menuOverlay = document.querySelector('#js-menu-overlay');
let closeMenu = document.querySelector('#close-menu-js');
let sitesMenu = document.querySelector('#js-toggle-sites');

function fadeOut(el)
{
        el.style.opacity = 1;

        (function fade() {
            if ((el.style.opacity -= .1) < 0) {
                    el.style.display = "none";
            } else {
                    requestAnimationFrame(fade);
            }
        })();
}

function fadeIn(el, display)
{
        el.style.opacity = 0;
        el.style.display = display || "block";

        (function fade() {
            var val = parseFloat(el.style.opacity);
            if (!((val += .1) > 1)) {
                    el.style.opacity = val;
                    requestAnimationFrame(fade);
            }
        })();
}

function toggleMenu()
{
    if ( mainMenu.classList.contains('visible') ) {
        document.body.classList.remove('locked');
        mainMenu.classList.remove('visible');
        fadeOut(menuOverlay);
    } else {
        document.body.classList.add('locked');
        mainMenu.classList.add('visible');
        fadeIn(menuOverlay);
    }
}

function toggleSitesMenu()
{
    if(sitesMenu.parentNode.classList.contains('visible')){
        sitesMenu.parentNode.classList.remove('visible');
    } 
    else 
    {
        sitesMenu.parentNode.classList.add('visible');
    }

    if(menuBtn.classList.contains('hidden')){
        menuBtn.classList.remove('hidden');
    }
    else
    {
        menuBtn.classList.add('hidden');
    }
}

sitesMenu.addEventListener("click", function(event){
    event.preventDefault();

    toggleSitesMenu();    
});

menuBtn.addEventListener("click", function(event) {
    event.preventDefault();

    toggleMenu();
});

menuOverlay.onclick = toggleMenu;

closeMenu.addEventListener("click", function(event) {
    event.preventDefault();

    toggleMenu();
});

window.addEventListener('scroll', function(e) {
    if ( document.body.scrollTop === 0 && document.documentElement.scrollTop === 0 && window.pageYOffset === 0 ) {
        mainMenu.classList.remove('scrolled');
    } else {
        mainMenu.classList.add('scrolled');
    }

    if(sitesMenu.parentNode.classList.contains('visible')){
        sitesMenu.parentNode.classList.remove('visible');
    }

    if(menuBtn.classList.contains('hidden')){
        menuBtn.classList.remove('hidden');
    }

});

// Special menu
let specialMenuBtns = document.querySelectorAll('.special-menu-btn');

for (var i = 0; i < specialMenuBtns.length; i++) {
    specialMenuBtns[i].addEventListener("click", function(event) {
        if (this.getAttribute("href").charAt(0) == "#") {
            event.preventDefault();

            toggleMenu();

            let target = this.getAttribute("href");

            let openMenuItem = document.querySelector('.menu-item[href="' + target + '"]');

            openMenuItem.closest('.has-sub').querySelector('.show-sub').classList.add('active');
            openMenuItem.closest('.has-sub').querySelector('ul').classList.add('active');
        }
    });
}