window.$ = require('jquery');
window.jQuery = window.$;

$(function() {
	var fetching = false;

	var $newsHolder = $('.js-news-holder');
	$('body').on('click', '.js-fetch-news', function (e) {

		e.preventDefault();

		var $this = $(this);
		var page = $this.data('page');

		if ( ! page )
		{
			page = 1;
		}

		page = page + 1;

		if ( ! fetching )
		{
			fetching = true;
			$.post(window.ajaxUrl, {
				action: 'fetch_news',
				page: page
			}, function(response)
			{
				fetching = false;

				if ( response )
				{
					$this.data('page', page);
					$newsHolder.append(response);
				}
			});
		}

	});


	//equalize heights
	var maxHeight = 0;

	$(".card-content, .program-card-content").each(function(){
	if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
	});

	$(".card-content, .program-card-content").height(maxHeight);

	var getUrlParameter = function getUrlParameter(sParam) {
		var sPageURL = decodeURIComponent(window.location.search.substring(1)),
			sURLVariables = sPageURL.split('&'),
			sParameterName,
			i;
		
		for (i = 0; i < sURLVariables.length; i++) {
			sParameterName = sURLVariables[i].split('=');
		
			if (sParameterName[0] === sParam) {
				return sParameterName[1] === undefined ? true : sParameterName[1];
			}
		}
	};

	if(getUrlParameter('autoplay')){
		var autoplay = getUrlParameter('autoplay');
		if(autoplay != 'undefined'){
			setTimeout(() => {
				$( "#play-button" ).trigger( "click" );
				$('html, body').animate({
					scrollTop: $('.article-part-text-holder').offset().top
				}, 1000);
			}, 2000);
		}
	}

});
